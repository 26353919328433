import React, { useCallback, useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

import { postToGetProjectDetail } from '@/api/projectService';
import { postNewUploadFile } from '@/api/storageService';

import {
  putNewExpense,
  postToGetExpenseDetail,
} from '@/api/expenseService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_MEMBER_OPTIONS,
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_EXPENSE_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PanelExpenseFormDetail from '@/components/Panel/Expense/PanelExpenseFormDetail';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PopupSelectFiles from '@/components/UI/Popup/PopupSelectFiles';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { expenseFormConfig } from '@/constant/dataConfig/expenseModule';
import { formatTime } from '@/utils/timeUtils';
import { integerConverter, floatConverter } from '@/utils/textUtils';
import {
  getNextApprovalRecord,
  getUserSubmitRecord,
  isVoucherAmountValid,
  isVoucherContentValid,
  isVoucherNoteValid,
} from '@/utils/expenseUtils';

const DivButtonsWrapper = styled(DivFlexRow)`
  padding: 10px 0 30px 135px;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  &:first-child { margin-left: 0 }
`;

const ButtonClear = styled(ButtonCancel)`
  margin-left: 10px;
  margin-top: 10px;
  width: 120px;

  @media (max-width: 425px) {
    margin-left: 0;
  }
`;

const ExpenseFormCreation = () => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const memberOptions = useSelector(state => state.srpOptions.memberOptions);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);

  const [expenseForm, setExpenseForm] = useState({
    ...cloneDeep(expenseFormConfig),
    user: userID,
    objectID: userID,
  });

  const [uploadLists, setUploadLists] = useState([]);
  const [fileUploadConfig, setFileUploadConfig] = useState({ type: '', index: 0 });

  const [alertText, setAlertText] = useState('');
  const [saveSuccessText, setSaveSuccessText] = useState('');
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showVouchersPopup, setShowVouchersPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);

  const [mapQuotationIDHasFile, setMapQuotationIDHasFile] = useState(new Map());

  const getUploadList = uploadLists[fileUploadConfig.index] === undefined
      ? [] : uploadLists[fileUploadConfig.index];

  const formSavable = expenseForm.projectID !== ''
    && expenseForm.payment.method !== ''
    && expenseForm.payment.creditCards
        .every(({ amount, user }) => amount !== '' && user !== '' );

  const amountTotal = expenseForm.vouchers
    .map(({ amount }) => integerConverter(amount))
    .reduce((acc, cur) => acc + cur, 0);

  const unTaxedAmountTotal = expenseForm.vouchers
    .map(({ untaxedAmount }) => integerConverter(untaxedAmount))
    .reduce((acc, cur) => acc + cur, 0);

  const taxTotal = expenseForm.vouchers
    .map(({ tax }) => integerConverter(tax))
    .reduce((acc, cur) => acc + cur, 0);

  const updateExpenseFormHandler = (key, value) => {
    const updateObj = { ...expenseForm };
    updateObj[key] = value;
    setExpenseForm({ ...updateObj });
  };

  const clearHandler = () => {
    setExpenseForm({
      ...cloneDeep(expenseFormConfig),
      user: userID,
      objectID: userID,
    });
    setUploadLists([]);
    setFileUploadConfig({ type: '', index: 0 });
  };

  const openSaveSuccessPopup = text => {
    setSaveSuccessText(text);
    setShowSaveSuccessPopup(true);
  };

  const openAlertPopup = text => {
    setAlertText(text);
    setShowAlertPopup(true);
  };

  const openFilesPopupHandler = (type, index) => {
    setFileUploadConfig({ type, index });
    setShowVouchersPopup(true);
  };

  const closeSuccessPopupHandler = () => {
    clearHandler();
    setShowSaveSuccessPopup(false);
  };

  const addFileListHandler = () => {
    setUploadLists(prevState => [...prevState, []]);
  };

  const removeFileListHandler = index => {
    const filteredVouchers = uploadLists
      .filter((item, i) => i !== index);

    setUploadLists([...filteredVouchers]);
  };

  const updateUploadFilesHandler = file => {
    const { index } = fileUploadConfig;

    const updateUploadLists = cloneDeep(uploadLists);
    updateUploadLists[index] = file;

    const updateVouchers = cloneDeep(expenseForm.vouchers);
    // updateVouchers[index].isVoucherUpload = file.length > 0;
    console.log("updateUploadFilesHandler");
    setMapQuotationIDHasFile(prev => new Map([...prev, [index, { isVoucherUpload: file.length > 0}]]));

    setUploadLists(updateUploadLists);
    setExpenseForm(prevState => ({
      ...prevState,
      vouchers: updateVouchers,
    }));
  };

  const postNewExpenseForm = async approvalStatus => {
    const { vouchers } = expenseForm;
    console.log("expenseFormCreation.js postNewExpenseForm expenseForm:" + JSON.stringify(expenseForm));
    if (vouchers.length === 0) {
      return openAlertPopup('請新增單據');
    }
    console.log("expenseFormCreation.js postNewExpenseForm amountTotal:" + amountTotal);
    if (amountTotal === 0) {
      return openAlertPopup('金額不可能零');
    }
    if (expenseForm.payment.method === "creditCard") {
      if (expenseForm.payment.creditCards.length === 0) {
        return openAlertPopup('請新增信用卡');
      }
      const sumOfCreditCards = expenseForm.payment.creditCards.reduce(function (previousValue, currentValue) {
          if (!isNaN(currentValue.amount)) {
            return previousValue + currentValue.amount;
          }
      }, 0);
      console.log("expenseFormCreation.js postNewExpenseForm sumOfCreditCards:" + sumOfCreditCards);
      console.log("expenseFormCreation.js postNewExpenseForm amountTotal:" + amountTotal);
      if (amountTotal !== sumOfCreditCards) {
        return openAlertPopup('信用卡總金額與請款金額不同');
      }
    }

    if (!vouchers.every(voucher => isVoucherContentValid(voucher))) {
      return openAlertPopup('支出憑證欄位未填或格式錯誤，請重新確認！');
    }

    if (!vouchers.every(voucher => isVoucherNoteValid(voucher))) {
      return openAlertPopup('請於"上下車地點(站名)或加油公升數"填入資訊！');
    }

    if (!vouchers.every(voucher => isVoucherAmountValid(voucher))) {
      return openAlertPopup('支出憑證請款總金額與未稅金額、稅額加總不符，請重新確認！');
    }

    if (vouchers === undefined || vouchers === null || vouchers.length === 0) {
      return openAlertPopup('請新增並上傳支出單據！');
    }

    // console.log("postNewExpenseForm mapQuotationIDHasFile.size:" + mapQuotationIDHasFile.size + " vouchers.length:" + vouchers.length);
    // if (mapQuotationIDHasFile.size !== vouchers.length) {
    //   return openAlertPopup('請選擇並上傳支出單據！');
    // }
    // if (!vouchers.every(function isBigEnough(element, index, array) {
    //   return element.isVoucherUpload;
    // })) {
    //   return openAlertPopup('請選擇並上傳支出單據！');
    // }

    const isSubmit = approvalStatus === 'projectManagerVerify';
    const applyDate = isSubmit ? formatTime(Date.now(), 'YYYY-MM-DD') : null

    try {
      const projectDetail = await postToGetProjectDetail(expenseForm.projectID);
      if (projectDetail.data.done === true) {
        // setShowLoadingPopup(false);
        return openAlertPopup('此專案已結案！')
      }
      setShowLoadingPopup(true);
      const { projectManager } = projectDetail.data;
      const records = [];

      if (isSubmit) {
        records.push(getUserSubmitRecord(userID));
        records.push(getNextApprovalRecord('draft')(projectManager));
      }
      
      const { data } = await putNewExpense({
        ...expenseForm,
        records,
        applyDate,
        approvalStatus,
        user: userID,
        price: amountTotal,
        payment: {
          ...expenseForm.payment,
          creditCards: expenseForm.payment.creditCards
            .map(({ user, amount }) => ({ user, amount: integerConverter(amount) }))
        },
        vouchers: expenseForm.vouchers
          .map(({ tax, amount, exchangeRate, untaxedAmount, accounting, ...remainItems }) => ({
            ...remainItems,
            tax: integerConverter(tax),
            amount: integerConverter(amount),
            untaxedAmount: integerConverter(untaxedAmount),
            exchangeRate: floatConverter(exchangeRate),
            accounting: {
              ...accounting,
              tax: integerConverter(accounting.tax),
              amount: integerConverter(accounting.amount),
              untaxedAmount: integerConverter(accounting.untaxedAmount),
            }
          })),
      });

      const expenseDetail = await postToGetExpenseDetail(data);
      const { vouchers, expenseCode } = expenseDetail.data;
      const quotationIDs = vouchers.map(({ quotationID }) => quotationID);

      for (let i = 0; i < uploadLists.length; i += 1) {
        const files = uploadLists[i];
        for (let j = 0; j < files.length; j += 1) {
          const file = files[j];
          const formData = new FormData();
          formData.append('upfile', file);
          formData.append('objectType', 'quotationVoucher');
          formData.append('objectID', quotationIDs[i]);
          formData.append('name', file.name);
          await postNewUploadFile(formData);
        }
      }

      setShowLoadingPopup(false);
      expenseCode === undefined
        ? openSaveSuccessPopup('')
        : openSaveSuccessPopup(`支出憑單流水號: ${ expenseCode }，請在單據附件填上此號碼。`);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '儲存失敗', err }));
      console.log(err);
    }
  };

  const initOptions = useCallback(() => {
    dispatch(FETCH_MEMBER_OPTIONS());
    dispatch(FETCH_PROJECT_ID_OPTIONS());
    dispatch(FETCH_EXPENSE_TYPE_OPTIONS({ enable: true, enableTags: true }));
  }, [dispatch]);

  useEffect(initOptions, [initOptions]);

  return (
    <MainContent>
      <PanelExpenseFormDetail
        title = "同仁款項申請"
        disabledObjectId = { true }
        voucherAddable = { true }
        hideAttachmentButton = { true }
        taxTotal = { taxTotal }
        amountTotal = { amountTotal }
        unTaxedAmountTotal = { unTaxedAmountTotal }
        expenseForm = { expenseForm }
        objectIDOptions = { memberOptions }
        projectIDOptions = { projectIDOptions }
        fileManageHandler = { () => { setShowVouchersPopup(true) }}
        addFileListHandler = { addFileListHandler }
        removeFileListHandler = { removeFileListHandler }
        openFilesPopupHandler = { openFilesPopupHandler }
        updateExpenseFormHandler = { updateExpenseFormHandler }
        disablePaymentDate = { true }
      >
        <DivFlexRow>
          <DivButtonsWrapper>
            <ButtonSave
              disabled = { !formSavable }
              onClick = { () => { postNewExpenseForm('draft') }}
            >儲存</ButtonSave>
            <ButtonSave
              disabled = { !formSavable }
              onClick = { () => { postNewExpenseForm('projectManagerVerify') }}
            >送審</ButtonSave>
            <ButtonClear
              onClick = { clearHandler }
            >清除欄位</ButtonClear>
          </DivButtonsWrapper>
        </DivFlexRow>
      </PanelExpenseFormDetail>
      { showLoadingPopup && <PopupLoading/> }
      {
        showVouchersPopup &&
        <PopupSelectFiles
          title = "單據管理"
          uploadFiles = { getUploadList }
          confirmHandler = { () => { setShowVouchersPopup(false) }}
          updateUploadFiles = { updateUploadFilesHandler }
        />
      }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "儲存成功"
            text = { saveSuccessText }
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "儲存失敗"
            text = { alertText }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
    </MainContent>
  );
};

export default ExpenseFormCreation;
