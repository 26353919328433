import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { DivPopupWrapper, DivPopupXLarge } from '@/component-style/Popup';
import { ButtonCancel, ButtonBasic } from '@/component-style/Button';
// import { DivFlexRow } from '@/component-style/RWDWrapper';
import { InputBasic } from '@/component-style/Input';
import SelectNormal from '@/components/UI/Select/SelectNormal';
import DateSelectorTwo from '@/components/UI/DateSelectorTwo';
import { formatTime } from '@/utils/timeUtils';
import Checkbox from '@/components/UI/Checkbox';
import moment from 'moment';
import { extraPaymentsParamsConfig } from '@/constant/dataConfig/sysConfigModule';

import PopupLoading from '@/components/UI/Popup/PopupLoading';

import { PSubtitle } from '@/component-style/TextElement';
import { integerConverter, floatConverter } from '@/utils/textUtils';
import MyInputNumber from '@/components/UI/MyInputNumber';
import { cloneDeep } from 'lodash';
import { leaveParamsConfig, leaveGiveHourParamsConfig, leaveApprovalParamsConfig } from '@/constant/dataConfig/sysConfigModule.js';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import { salaryExtraPaymentOptions } from '@/constant/options';
import TableWrapper from '@/components/UI/TableWrapper';
import PanelAddWageStructure from '@/components/Panel/Wage/PanelAddWageStructure';

import {
  DivFullWidthWrapper,
} from '@/component-style/RWDWrapper';

import {
  postCreateExtraPayments,
  getExtraPaymentsById,
  updateExtraPayments,
} from '@/api/financeService';

import { TableHead, TableRow, ThItem } from '@/component-style/Table';

import TableWageStructure from '@/components/Table/TableWageStructure';


// import {
//   // postCreateLeaveSetting,
//   patchLeaveSetting,
//   postCreateLeaveGiveSetting,
//   deleteLeaveGiveSetting,
//   postCreateLeaveGiveHourSetting,
//   postCreateLeave,
//   patchUpdateLeave,
//   postAddLeaveGiveHour,
//   getLeaveGiveHourById,
//   deleteRemoveLeaveGiveHours,
//   putSaveLeaveApprovalSetting,
//   deleteLeaveApprovalSetting,
//   getPaycheckStructures,
// } from '@/api/financeService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  DivFlexRow,
  DivItemWrapper,
} from '@/component-style/RWDWrapper';

const THead = styled(TableHead)`
  min-width: 1560px;
`;

const Th120 = styled(ThItem)`
  width: 120px;
`;

const DivButtonsWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;

const H3Title = styled.h3`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #8b8b8b;
  margin-bottom: 10px;
`;

const ButtonDiscard = styled(ButtonCancel)`
  width: 120px;
  margin-right: 10px;
`;

const ButtonSave = styled(ButtonBasic)`
  width: 120px;

  &:first-child { margin-right: 10px }
`;

const PopupLeaveOption = props => {
  const dispatch = useDispatch();

  const [extraPaymentsParams, setExtraPaymentsParams] = useState(cloneDeep(extraPaymentsParamsConfig));

  const getExtraPayment = async () => {
    // console.log(`PopupSalaryExtraPayment.js getExtraPayment() moment().startOf('month').format('YYYY-MM-DD')=${moment().startOf('month').format('YYYY-MM-DD')}`);
    console.log(`PopupLeaveOption.js getExtraPayment() editExtraPaymentId=${props.editExtraPaymentId}`);
  };

  const initExtraPayment = useCallback(
    getExtraPayment
  , []
  );

  useEffect(() => {
    initExtraPayment();
  }, [initExtraPayment]);

  return (
    <DivPopupWrapper>
      <DivPopupXLarge>
        <DivFlexRow>
          <DivItemWrapper>
            <PSubtitle>加、減項</PSubtitle>
          </DivItemWrapper>
          <DivItemWrapper>
            <PSubtitle>名稱</PSubtitle>
          </DivItemWrapper>
        </DivFlexRow>
        <DivButtonsWrapper>
          <ButtonDiscard
            onClick = { props.cancelHandler }
          >關閉
          </ButtonDiscard>
        </DivButtonsWrapper>
      </DivPopupXLarge>
    </DivPopupWrapper>
  );
};

export default PopupLeaveOption;
