export const expenseQuotationConfig = {
  user: '',
  projectID: '',
  vendorID: '',
  quotationType: 'project',
  hourlyRate: '',
  note: '',
  quotedPrice: '',
  price: '',
};

export const expenseFormConfig = {
  user: '',
  projectID: '',
  isImportant: false,
  object: 'user',
  objectID: '',
  applyDate: null,
  predictDate: null,
  paymentDate: null,
  approvalStatus: '',
  price: 0,
  payment: {
    method: '',
    settleDays: '',
    creditCards: [],
  },
  vouchers: [],
  records: [],
  note: '',
  exportedAccountingSystem: false,
};

export const expenseVoucherConfig = {
  voucherType: '',
  date: null,
  voucherCode: '',
  taxID: '',
  untaxedAmount: '',
  tax: '',
  amount: '',
  exchangeRate: 0,
  type: '',
  tag: '',
  note: '',
  carbonEmission: 0,
  accounting: {
    accountingType: '',
    notForBusinessTax: false,
    notForAccountingSys: false,
    personalService: false,
    untaxedAmount: 0,
    tax: 0,
    amount: 0,
    declareMonth: null,
    personalIncomeDeclareDate: null,
    declareType: '',
  }, 
};
