import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useLocation } from "react-router-dom"

import { postToGetProjectDetail } from '@/api/projectService';
import { postToGetScheduleTimeConfig } from '@/api/attendanceService';
import PopupLeaveOption from '@/components/UI/Popup/PopupLeaveOption';

import {
  getLeave,
  postCreateLeaveApplication,
  getLeaveApplications,
  getCalculateLeaveDurationForUser,
  getUserLeaveOptions,
  getUserLeaveHours,
} from '@/api/financeService';

import {
  putNewWorkingHours,
  postToGetWorkingHours,
} from '@/api/attendanceService';

import {
  OPEN_SAVE_FAIL_POPUP,
  CLOSE_SAVE_FAIL_POPUP,
} from '@/store/actions/apiFailPopupAction';

import {
  FETCH_PROJECT_ID_OPTIONS,
  FETCH_WORKINGHOURS_TYPE_OPTIONS,
} from '@/store/actions/srpOptionsAction';

import MainContent from '@/component-style/MainContent';
import PopupLoading from '@/components/UI/Popup/PopupLoading';
import PopupExecuteResult from '@/components/UI/Popup/PopupExecuteResult';
import PanelLeaveDetail from '@/components/Panel/PanelLeaveDetail';
import TableLeaveApplication from '@/components/Table/Attendance/TableLeaveApplication';
import { ButtonBasic, ButtonCancel } from '@/component-style/Button';
import { DivFlexRow } from '@/component-style/RWDWrapper';

import { cloneDeep } from 'lodash';

import { overtimeConfig } from '@/constant/dataConfig/attendanceModule';
import { leaveApplicationConfig } from '@/constant/dataConfig/financeModule';

import moment from 'moment';
import {
  diffTime,
  diffTimeSubtractLunchBreak,
  diffTimeWithFloat,
  toUTCTime,
  formatTime,
  getEndOfUnit,
  getStartOfUnit,
  getOvertimeDateType,
  canWorkOvertimeOnSaturdayOrSunday,
} from '@/utils/timeUtils';

const DivPanelContainer = styled.div`
  margin-bottom: 40px;
`;

const DivButtonsWrapper = styled(DivFlexRow)`
  max-width: 1535px;
  padding: 0;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media (max-width: 425px) {
    justify-content: center;
    padding-left: 0;
  }
`;

const ButtonSave = styled(ButtonBasic)`
  margin-right: 10px;
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const ButtonClear = styled(ButtonCancel)`
  width: 120px;

  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`;

const LeaveApplication = () => {
  const history = useHistory()
  // const { workingDate } = useParams();
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const previousSearchedWorkingDate = params.get('workingDate'); // workingDate
  // console.log("OvertimeApplication previousSearchedWorkingDate:" + previousSearchedWorkingDate);
  
  const dispatch = useDispatch();
  const userID = useSelector(state => state.login.token);
  const username = useSelector(state => state.login.user);
  const projectIDOptions = useSelector(state => state.srpOptions.projectIDOptions);
  const workingHoursTypeOptions = useSelector(state => state.srpOptions.workingHoursTypeOptions);
  const workingHoursTagDictionary = useSelector(state => state.srpOptions.workingHoursTagDictionary);
  const showSaveFailPopup = useSelector(state => state.apiFail.showSaveFailPopup);
  const apiFailPopupTitle = useSelector(state => state.apiFail.title);
  const apiFailPopupMessage = useSelector(state => state.apiFail.message);
  
  const [leaveApplicationRecords, setLeaveApplicationRecords] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [overtime, setOvertime] = useState({ ...overtimeConfig });

  const [leaveApplication, setLeaveApplication] = useState(
    {
      ...cloneDeep(leaveApplicationConfig),
    }
  );
  const [leaveHours, setLeaveHours] = useState("");

  const [searchLeaveFrom, setSearchLeaveFrom] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [searchLeaveEnd, setSearchLeaveEnd] = useState(moment().endOf('month').format('YYYY-MM-DD'));

  const [leaveSettings, setLeaveSettings] = useState([]);
  const [originalLeaveSettinsOptions, setOriginalLeaveSettinsOptions] = useState([]);
  const [leaveSettinsOptions, setLeaveSettinsOptions] = useState([]);
  // setOvertime({
  //   ...overtimeConfig,
  //   workingDate: previousSearchedWorkingDate,
  // });
  // console.log("overtimeApplication.js overtime" + JSON.stringify(overtime));
  const [overtimeParams, setOvertimeParam] = useState({
    overTimeLimit: 0,
    freeTimeLimit: 0,
    extraPayLimit: 0,
  });

  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [showAlertPopupOverlapped, setShowAlertPopupOverlapped] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showSaveSuccessPopup, setShowSaveSuccessPopup] = useState(false);
  const [showAlertOnlyWorkOvertimeOnSaturdayOrSunday, setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday] = useState(false);
  const [showUserLeaveOptionsPopup, setShowUserLeaveOptionsPopup] = useState(false);

  const overtimeMinutes = diffTimeSubtractLunchBreak(overtime.startTimeLocal, overtime.endTimeLocal, 'minutes') || 0;

  var overtimeDateType = "workdays";

  const updateLeaveApplicationHandler = (key, value) => {
    console.log(`leaveApplication.js updateLeaveApplicationHandler key=${key}&value=${value}`);
    const updateObj = { ...leaveApplication };
    updateObj[key] = value;
    setLeaveApplication({...updateObj});
  };

  const closeSuccessPopupHandler = () => {
    // console.log("closeSuccessPopupHandler");
    setShowSaveSuccessPopup(false);
    setOvertime(prevState => ({
      ...overtimeConfig,
      workingDate: prevState.workingDate,
    }));
    fetchLeaveApplication();
  };

  const setCurrentPageHandler = page => {
    setCurrentPage(page);
    // fetchOvertimes(overtime.workingDate, page);
  };

  const clearHandler = () => {
    setOvertime({
      ...overtimeConfig,
      workingDate: null,
    });
    // setLeaveApplicationRecords([]);
    // setTotalCount(0);

    setLeaveApplication({
      ...leaveApplicationConfig,
    });
  };

  const workingDateChangeHanlder = value => {
    const params = new URLSearchParams()
    if (value !== null) {
      // console.log("overtimeApplication.js workingDateChangeHanlder date:" + value);
      params.append("workingDate", formatTime(value, 'YYYY-MM-DD'))
      history.push({search: params.toString()})
    }
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    setOvertime({
      ...overtimeConfig,
      workingDate: value,
      userID: userID,
    });
    setLeaveApplicationRecords([]);
    setCurrentPage(1);
    // value !== null && fetchOvertimes(value, 1);
  };

  //Modified by Ian
  /*const isOvertimeAlert = mins => mins > overtimeParams.overTimeLimit * 60
    || (overtime.overtimeType === 'freeTime' && mins > overtimeParams.freeTimeLimit * 60)
    || (overtime.overtimeType === 'extraPay' && mins > overtimeParams.extraPayLimit * 60);*/
  const isOvertimeAlert = (overtimeDateType, mins) => 
  {
    // console.log("isOvertimeAlert overtimeParams:" + JSON.stringify(overtimeParams));
    // console.log("isOvertimeAlert overtimeDateType:" + overtimeDateType);
    var boolOvertimeHourOverLimit = false;
    let thisDayOvertimerWorkingHours = diffTimeWithFloat(overtime.startTimeLocal, overtime.endTimeLocal, 'hours');
    for (const objOvertimeRecord of leaveApplicationRecords) {
      // console.log("isOvertimeAlert objOvertimeRecord.startTimeLocal" + objOvertimeRecord.startTimeLocal);
      thisDayOvertimerWorkingHours += diffTimeWithFloat(objOvertimeRecord.startTimeLocal, objOvertimeRecord.endTimeLocal, 'hours')
    }
    // console.log("isOvertimeAlert thisDayOvertimerWorkingHours:" + thisDayOvertimerWorkingHours);
    if (overtimeDateType === "workdays") {
      if (thisDayOvertimerWorkingHours > overtimeParams.overTimeLimit) {
        boolOvertimeHourOverLimit = true;
      }
    } else {
      if (thisDayOvertimerWorkingHours > 12) {
        boolOvertimeHourOverLimit = true;
      }
    }
    return boolOvertimeHourOverLimit;
  };

  const isOverTimesOverlapped = () =>
  {
    let overlapped = false;
    let overtimeArray = [];
    overtimeArray.push({startTimeLocal:overtime.startTimeLocal, endTimeLocal: overtime.endTimeLocal});
    for (const objOvertimeRecord of leaveApplicationRecords) {
      overtimeArray.push({startTimeLocal:objOvertimeRecord.startTimeLocal, endTimeLocal: objOvertimeRecord.endTimeLocal});
    }
    overtimeArray.sort((a,b)=>new Date(a.startTimeLocal) - new Date(b.startTimeLocal));
    if (overtimeArray.length >= 2) {
      for (var i = 0; i < overtimeArray.length - 1; i++) {
        if (new Date(overtimeArray[i+1].startTimeLocal) >= new Date(overtimeArray[i].startTimeLocal) && new Date(overtimeArray[i+1].startTimeLocal) < new Date(overtimeArray[i].endTimeLocal)) {
          /*console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i+1].startTimeLocal);
          console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i].startTimeLocal);
          console.log("isOverTimesOverlapped overtimeArray:" + overtimeArray[i+1].endTimeLocal);*/
          overlapped = true;
          break;
        }
      }
    }
    //console.log("isOverTimesOverlapped overtimeArray:" + JSON.stringify(overtimeArray));
    //console.log("isOverTimesOverlapped overlapped" + overlapped);
    return overlapped;
  }
  //Modified by Ian

  const submitHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate } = overtime;

    overtime.userID = userID;    
    overtimeDateType = await getOvertimeDateType(overtime);

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const currentHours = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);
      let boolCanWorkOvertimeOnSaturdayOrSunday = await canWorkOvertimeOnSaturdayOrSunday(overtime);

      !boolCanWorkOvertimeOnSaturdayOrSunday ? setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(true) : 
        isOverTimesOverlapped() 
          ? setShowAlertPopupOverlapped(true)
          : isOvertimeAlert(overtimeDateType, overtimeMinutes + currentHours)
              ? setShowAlertPopup(true)
              : submitNewOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveHandler = async () => {
    setShowLoadingPopup(true);
    const { workingDate } = overtime;

    // console.log("saveHandler userID:" + userID);
    overtime.userID = userID;
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    
    // let resultOfOvertimeDateType = await getOvertimeDateType(overtime);
    // setOvertimeDateType(resultOfOvertimeDateType);
    overtimeDateType = await getOvertimeDateType(overtime);
    // console.log("saveNewOvertime overtimeDateType:" + overtimeDateType);

    try {
      const { data } = await postToGetWorkingHours({
        isOverTime: true,
        userIDs: [userID],
        startDate: formatTime(getStartOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        endDate: formatTime(getEndOfUnit(workingDate, 'month'), 'YYYY-MM-DD'),
        amount: 500,
        page: 1,
      });

      const currentHours = data.workingHours
        .map(({ startTimeLocal, endTimeLocal }) => ({ startTimeLocal, endTimeLocal }))
        .reduce((acc, { startTimeLocal, endTimeLocal }) =>
          acc + diffTime(startTimeLocal, endTimeLocal, 'minutes')
        , 0);

      setShowLoadingPopup(false);

      console.log("saveHandler overtimeMinutes:" + overtimeMinutes);
      let boolCanWorkOvertimeOnSaturdayOrSunday = await canWorkOvertimeOnSaturdayOrSunday(overtime);
      // console.log("saveHandler boolCanWorkOvertimeOnSaturdayOrSunday:" + boolCanWorkOvertimeOnSaturdayOrSunday);
      !boolCanWorkOvertimeOnSaturdayOrSunday ? setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(true) :
        isOverTimesOverlapped()  
          ? setShowAlertPopupOverlapped(true)
          : isOvertimeAlert(overtimeDateType, overtimeMinutes + currentHours)
              ? setShowAlertPopup(true)
              : saveNewOvertime();

    } catch (err) {
      setShowLoadingPopup(false);
      console.log(err)
    };
  };

  const saveNewOvertime = async () => {
    setShowLoadingPopup(true);

    // let overtimeDateType = "";
    // console.log("saveNewOvertime userID:" + userID);
    // overtime.userID = userID;
    // console.log("workingDateChangeHanlder overtime:" + JSON.stringify(overtime));
    
    let overtimeDateType = await getOvertimeDateType(overtime);
    // console.log("saveNewOvertime overtimeDateType:" + overtimeDateType);

    try {
      await putNewWorkingHours({
        ...overtime,
        userID,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
        overtimeDateType : overtimeDateType,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  const submitNewLeaveApplication = async () => {
    setShowLoadingPopup(true);

    console.log(`leaveApplication.js leaveApplication=${JSON.stringify(leaveApplication)}`);

    try {
      const objLeaveApplication = {
        leaveId: leaveApplication.leaveId,
        description: leaveApplication.description,
        userId: userID,
        leaveFrom: formatTime(leaveApplication.leaveFrom, 'YYYY-MM-DD HH:mm:ss'),
        leaveTo: formatTime(leaveApplication.leaveTo, 'YYYY-MM-DD HH:mm:ss'),
        isAllocated: leaveApplication.isAllocated,
      };
      const singleLeaveSetting = leaveSettings.find((element) => element.id === leaveApplication.leaveId);
      objLeaveApplication.isAllocated = singleLeaveSetting.isAllocated;
      delete objLeaveApplication.leaveHours;

      await postCreateLeaveApplication(objLeaveApplication);

      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      console.log(`submitNewLeaveApplication() JSON.stringify(err.response)=${JSON.stringify(err.response)}`);
      if (err.response.data.code === 1417) {
        setShowAlertMessage("此時段已申請請假");
        setShowAlertPopup(true);
      }  else {
        dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      }
      console.log(err);
    }
  };

  const calculateLeaveHour = async () => {
    setShowLoadingPopup(true);

    // console.log("leaveApplication.js calculateLeaveHour() JSON.stringify(leaveApplication):" + JSON.stringify(leaveApplication));

    try {
      const dataForCalculateLeaveDuration = {};
      dataForCalculateLeaveDuration.leaveId = leaveApplication.leaveId;
      console.log(`leaveApplication.js calculateLeaveHour() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      dataForCalculateLeaveDuration.leaveFrom = formatTime(leaveApplication.leaveFrom, 'YYYY-MM-DD HH:mm:ss');
      dataForCalculateLeaveDuration.leaveTo = formatTime(leaveApplication.leaveTo, 'YYYY-MM-DD HH:mm:ss');
      console.log(`leaveApplication.js calculateLeaveHour() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      const singleLeaveSetting = leaveSettings.find((element) => element.id === leaveApplication.leaveId);
      console.log(`leaveApplication.js calculateLeaveHour() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      dataForCalculateLeaveDuration.isAllocated = singleLeaveSetting.isAllocated;
      console.log(`leaveApplication.js calculateLeaveHour() JSON.stringify(dataForCalculateLeaveDuration) ${JSON.stringify(dataForCalculateLeaveDuration)}`);
      const {data} = await getCalculateLeaveDurationForUser(userID, dataForCalculateLeaveDuration);
      console.log(`leaveApplication.js calculateLeaveHour() JSON.stringify(data): + JSON.stringify(data)`);
      // setLeaveHours(data.durationInHours);
      updateLeaveApplicationHandler("durationInSeconds", data.durationInSeconds);

      setShowLoadingPopup(false);
    } catch (err) {
      // console.log(JSON.stringify(err));
      console.log(`calculateLeaveHour() ${JSON.stringify(err.response)}`);
      console.log(`calculateLeaveHour() ${err.response.data.code}`);
      setShowLoadingPopup(false);
      if (err.response.data.code === 400) {
        setShowAlertMessage("請假日為假日");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1410) {
        setShowAlertMessage("此假別時數不足");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1409) {
        setShowAlertMessage("請假時數小於最低請假時數");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1412) {
        setShowAlertMessage("請假時間為午休");
        setShowAlertPopup(true);
      } else if (err.response.data.code === 1414) {
        setShowAlertMessage("此假別不適用您所屬部門");
        setShowAlertPopup(true);
      } else {
        // console.log(`leaveApplication.js calculateLeaveHour()`);
        dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      }
      console.log(err);
    }
  };

  const submitNewOvertime = async () => {
    let overtimeDateType = await getOvertimeDateType(overtime);
    try {
      await putNewWorkingHours({
        ...overtime,
        userID,
        workingDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
        startTimeLocal: formatTime(overtime.startTimeLocal),
        endTimeLocal: formatTime(overtime.endTimeLocal),
        submitDate: formatTime(Date.now(), 'YYYY-MM-DD'),
        approvementStatus: 'unapproved',
        freeTimeMins: overtime.overtimeType === 'freeTime' ? overtimeMinutes : 0,
        extraPayMins: overtime.overtimeType === 'extraPay' ? overtimeMinutes : 0,
        overtimeDateType : overtimeDateType,
      });
      setShowLoadingPopup(false);
      setShowSaveSuccessPopup(true);
    } catch (err) {
      setShowLoadingPopup(false);
      dispatch(OPEN_SAVE_FAIL_POPUP({ title: '新增失敗', err }));
      console.log(err);
    }
  };

  const fetchLeaveSetting = async () => {
    setShowLoadingPopup(true);

    try {
      const { data } = await getUserLeaveOptions(userID, null);
      console.log(`leaveApplication.js data=${JSON.stringify(data)}`);
      setLeaveSettings(data.options);
      const sorted = data.options.map(element => {
        if (element.yearlySeconds !== undefined) {
          console.log(`leaveApplication.js fetchLeaveSetting() element.yearlySeconds=${JSON.stringify(element.yearlySeconds)}`);
          var description = element.name;
          element.yearlySeconds.forEach(function (item, index) {
            description += item.year + "年(" + Math.round((item.used / 3600) * 100) / 100 + "/" + Math.round((item.total / 3600) * 100) / 100 + "),";
          });
          description = description.replace(/,\s*$/, "");
          console.log(`leaveApplication.js fetchLeaveSetting() description=${JSON.stringify(description)}`);
          return {value: element.id, desc: description};
        } else {
          return {value: element.id, desc: element.name + "(" + Math.round((element.usedSeconds / 3600) * 100) / 100 + "/" + Math.round((element.availableSeconds / 3600) * 100) / 100 + ")" };
        }
      });
      console.log(`leaveApplication.js sorted=${JSON.stringify(sorted)}`);
      setLeaveSettinsOptions(sorted);
      const sortedOriginal = data.options.map(element => {
        return {value: element.id, desc: element.name};
      });
      console.log(`leaveApplication.js sortedOriginal=${JSON.stringify(sortedOriginal)}`);
      setOriginalLeaveSettinsOptions(sortedOriginal);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  }

  const fetchLeaveAvailableHours = async () => {
    // setShowLoadingPopup(true);

    try {
      const { data } = await getUserLeaveHours(userID);
      console.log(`leaveApplication.js fetchLeaveAvailableHours: ${JSON.stringify(data)}`);
    } catch (err) { console.log(err) }

    // setShowLoadingPopup(false);
  };
  
  const fetchLeaveApplication = async (date = leaveApplication.leaveFrom, page = currentPage) => {
    setShowLoadingPopup(true);

    try {
      console.log(`leaveApplication.js fetchLeaveApplication leaveApplication.leaveFrom=${leaveApplication.leaveFrom}&leaveApplication.leaveTo=${leaveApplication.leaveTo}`);
      var data = [];
      if (leaveApplication.leaveFrom !== null && leaveApplication.leaveTo !== null) {
        setSearchLeaveFrom(moment(leaveApplication.leaveFrom).format('YYYY-MM-DD'));
        setSearchLeaveEnd(moment(leaveApplication.leaveTo).format('YYYY-MM-DD'));
        const temp = await getLeaveApplications({
          userId: userID,
          leaveFrom: moment(leaveApplication.leaveFrom).format('YYYY-MM-DD'), 
          leaveTo: moment(leaveApplication.leaveTo).add(1, 'days').format('YYYY-MM-DD')
        });
        data = temp.data;
      } else {
        const temp = await getLeaveApplications({
          userId: userID,
          leaveFrom: moment(searchLeaveFrom).format('YYYY-MM-DD'), 
          leaveTo: moment(searchLeaveEnd).add(1, 'days').format('YYYY-MM-DD')
        });
        data = temp.data;
      }
      console.log("leaveApplication.js fetchLeaveApplication JSON.stringify(data):" + JSON.stringify(data));
      const { applications } = data;
      const tempApplicationRecords = [];

      for (let i = 0; i < applications.length; i += 1 ) {
        //   const { projectID } = workingHours[i];
        //   const projectInfo = await postToGetProjectDetail(projectID);
        //   const { projectCode, projectName } = projectInfo.data;
        tempApplicationRecords.push({
          ...applications[i],
        });
      }

      setLeaveApplicationRecords([ ...tempApplicationRecords ]);
      // setTotalCount(totalCount || 0);
    } catch (err) { console.log(err) }

    setShowLoadingPopup(false);
  };

  const refreshLeaveApplication = useCallback(
    fetchLeaveApplication, [leaveApplication.leaveFrom, leaveApplication.leaveTo]);

  const showUserLeaveOptionsPopupHandler = () => {
    setShowUserLeaveOptionsPopup(true);
  }

  const cancelHandler = () => {
    setShowUserLeaveOptionsPopup(false);
  }

  useEffect(() => {
    refreshLeaveApplication();
  }, [refreshLeaveApplication]);

  const initLeaveSetting = useCallback(
    fetchLeaveSetting, []);

  useEffect(() => {
    // initOptions();
    initLeaveSetting();
  }, [initLeaveSetting]);

  const handlePreviousSearch = useCallback(() => {
    if (previousSearchedWorkingDate !== undefined && previousSearchedWorkingDate !== "") {
      // console.log("handlePreviousSearch" + previousSearchedWorkingDate);
      setOvertime({
        ...overtimeConfig,
        workingDate: previousSearchedWorkingDate,
      });
      setLeaveApplicationRecords([]);
      setCurrentPage(1);
    }
  }, [previousSearchedWorkingDate]);
  
  useEffect(() => {
    handlePreviousSearch();
  }, [handlePreviousSearch]);

  // useEffect(() => {
  //   console.log("useEffect :" + overtime.workingDate);
  //   retrieveOvertime();
  // }, [retrieveOvertime, overtime.workingDate]);

  useEffect(() => {
    async function fetchOvertimesByIan() {
      // console.log("fetchOvertimesByIan useEffect fetchOvertimesByIan :" + overtime.workingDate);
      // console.log("fetchOvertimesByIan useEffect :" + userID);
      setShowLoadingPopup(true);
      try {
        const { data } = await postToGetWorkingHours({
          isOverTime: true,
          userIDs: [userID],
          startDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
          endDate: formatTime(overtime.workingDate, 'YYYY-MM-DD'),
          amount: 10,
          page: currentPage,
        });
        // console.log("fetchOvertimesByIan useEffect :" + JSON.stringify(data));
        const { workingHours, totalCount } = data;
        const overtimeRecords = [];

        for (let i = 0; i < workingHours.length; i += 1 ) {
          const { projectID } = workingHours[i];
          const projectInfo = await postToGetProjectDetail(projectID);
          const { projectCode, projectName } = projectInfo.data;
          overtimeRecords.push({
            ...workingHours[i],
            username,
            projectCode,
            projectName,
          });
        }

        setLeaveApplicationRecords([ ...overtimeRecords ]);
        setTotalCount(totalCount || 0);
      } catch (err) { console.log(err) }

      setShowLoadingPopup(false);
    }

    if (overtime.workingDate !== null) {
      fetchOvertimesByIan();
    }
  }, [username, userID, overtime.workingDate, currentPage]);

  useEffect(() => {
    if (leaveApplication.leaveFrom !== null && leaveApplication.leaveTo !== null && leaveApplication.leaveId !== "") {
      console.log(`leaveApplication.js useEffect() going to call calculateLeavHour()`);
      // console.log(`leaveApplication.js useEffect() JSON.stringify(leaveApplication)=${JSON.stringify(leaveApplication)}`);
      calculateLeaveHour();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveApplication.leaveFrom, leaveApplication.leaveTo, leaveApplication.leaveId]);

  return (
    <MainContent>
      <DivPanelContainer>
        <PanelLeaveDetail
          title = { "休假申請" }
          overtime = { overtime }
          leaveApplication = { leaveApplication }
          leaveHours = { leaveHours }
          projectIDOptions = { projectIDOptions }
          workingHoursTypeOptions = { workingHoursTypeOptions }
          workingHoursTagDictionary = { workingHoursTagDictionary }
          updateLeaveApplicationHandler = { updateLeaveApplicationHandler }
          workingDateChangeHanlder = { workingDateChangeHanlder }
          leaveSettinsOptions = { leaveSettinsOptions }
        >
          <DivFlexRow>
            <DivButtonsWrapper>
              {/* <ButtonSave
                onClick = { showUserLeaveOptionsPopupHandler }
              >個人假別資訊</ButtonSave> */}
              <ButtonSave
                onClick = { submitNewLeaveApplication }
                disabled = { leaveApplication.leaveFrom === null
                  || leaveApplication.leaveTo === null
                  || leaveApplication.leaveId === null
                  || leaveApplication.leaveId === ""
                }
              >送審</ButtonSave>
              <ButtonSave
                onClick = { calculateLeaveHour }
                disabled = { leaveApplication.leaveFrom === null
                  || leaveApplication.leaveTo === null
                  || leaveApplication.leaveId === null
                  || leaveApplication.leaveId === ""
                }
              >計算休假時數</ButtonSave>
              {/* <ButtonSave
                onClick = { submitHandler }
                disabled = {  overtime.startTimeLocal === null
                  || overtime.endTimeLocal === null
                  || overtimeMinutes <= 0
                }
              >送審</ButtonSave> */}
              <ButtonClear
                onClick = { clearHandler }
              >清除欄位</ButtonClear>
            </DivButtonsWrapper>
          </DivFlexRow>
        </PanelLeaveDetail>
      </DivPanelContainer>
      <TableLeaveApplication
        leaveApplicationRecords = { leaveApplicationRecords }
        totalCount = { totalCount }
        currentPage = { currentPage }
        pageChangedHandler = { value => {
          setCurrentPageHandler(value);
        }}
        leaveSettinsOptions = { originalLeaveSettinsOptions }
        searchLeaveFrom = { searchLeaveFrom }
        searchLeaveEnd = { searchLeaveEnd }
      />
      { showLoadingPopup && <PopupLoading/> }
      {
        showSaveSuccessPopup &&
          <PopupExecuteResult
            title = "新增成功-若有附件欲上傳，請點選剛剛建立的請假申請單。"
            confirmHandler = { closeSuccessPopupHandler }
          />
      }
      {
        showAlertPopup &&
          <PopupExecuteResult
            title = "申請失敗"
            text = { showAlertMessage }
            confirmHandler = { () => { setShowAlertPopup(false) } }
          />
      }
      {
        showAlertPopupOverlapped &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "加班工時重覆，無法新增工時"
            confirmHandler = { () => { setShowAlertPopupOverlapped(false) } }
          />
      }
      {
        showAlertOnlyWorkOvertimeOnSaturdayOrSunday &&
          <PopupExecuteResult
            title = "新增失敗"
            text = "除非遇到天災、事變或突發事件需出勤工作，其他原因不得申請例假日加班。"
            confirmHandler = { () => { setShowAlertOnlyWorkOvertimeOnSaturdayOrSunday(false) } }
          />
      }
      {
        showSaveFailPopup &&
          <PopupExecuteResult
            title = { apiFailPopupTitle }
            text = { apiFailPopupMessage }
            confirmHandler = { () => {
              dispatch(CLOSE_SAVE_FAIL_POPUP());
            }}
          />
      }
      { 
        showUserLeaveOptionsPopup &&
          <PopupLeaveOption
            cancelHandler = { cancelHandler }
          />
      }
    </MainContent>
  );
};

export default LeaveApplication;
